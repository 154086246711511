section {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.intro_sec {
  height: calc(100vh - 60px);
  min-height: 700px;
  height: 100vh;
  margin-top: -60px;
}

@media (max-width: 991.98px) {
  .intro_sec {
    display: block;
    height: auto !important;
  }
}

.intro_sec .text,
.intro_sec .h_bg-image {
  width: 50%;
}

@media (max-width: 991.98px) {
  .intro_sec .text,
  .intro_sec .h_bg-image {
    width: 100%;
  }
}

.intro_sec .intro {
  max-width: 450px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .intro_sec .intro {
    max-width: 700px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.intro_sec .text h1 {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 700;
}

.intro_sec .text h3 {
  font-size: 16px;
  font-weight: 700;
}

.intro_sec .h_bg-image {
  background-size: cover;
  background-position: center;
  min-height: 700px;
  position: relative;
}

@media (max-width: 991.98px) {
  .intro_sec .h_bg-image {
    height: 600px;
    min-height: 75vh;
    margin-bottom: 30px;
  }
}

.intro_sec .h_bg-image {
  filter: saturate(0.5);
}
