.blog-description {
  text-align: justify;
  text-justify: inter-word;
}

.image {
  cursor: zoom-in;
}

.blog-title,
.blog-subtitle {
  font-family: var(--font-family) !important;
}
